.progress {
    height: 2.5px;
    width: 200px;
    background: linear-gradient(#1a13e0 0 0),
        linear-gradient(#1a13e0 0 0),
        #dbdcef;
    background-size: 60% 100%;
    background-repeat: no-repeat;
    animation: progress-7x9cg2 3s infinite;
 }
 
 @keyframes progress-7x9cg2 {
    0% {
       background-position: -150% 0,-150% 0;
    }
 
    66% {
       background-position: 250% 0,-150% 0;
    }
 
    100% {
       background-position: 250% 0, 250% 0;
    }
 }